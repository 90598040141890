import React from "react"
import { HStack, Text } from "@chakra-ui/react"
import { useShopify, useShopifyPriceRange } from "@app/hooks/useShopify"

type Props = {
  product: any
  loading?: boolean
}

const ProductPriceRange: React.FC<Props> = ({ product, loading }) => {
  const { formattedMinPrice, priceMin } = useShopifyPriceRange(product)
  const { formatMoney } = useShopify()
  const compareAtPrice = product?.compareAtPriceRange?.minVariantPrice?.amount
    ? parseInt(product.compareAtPriceRange.minVariantPrice?.amount || 0)
    : 0
  const onSale = compareAtPrice > priceMin
  const formattedCompareAtPrice = formatMoney(compareAtPrice, product?.compareAtPriceRange?.minVariantPrice?.currencyCode)

  return !loading ? (
    <HStack mt={2}>
      <Text
        as={onSale ? "s" : undefined}
        size="smSemiLarge"
        color={onSale ? "typography.body600" : "typography.headlines900"}
        textTransform="uppercase"
      >
        {onSale ? formattedCompareAtPrice : formattedMinPrice}
      </Text>
      {onSale ? (
        <Text color="functional.errorContent" size="smSemiLarge">
          &nbsp;{formattedMinPrice}
        </Text>
      ) : null}
    </HStack>
  ) : null
}

export default React.memo(ProductPriceRange)
