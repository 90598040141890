import React, { useEffect, useRef } from "react"
import { Box, Container, Heading } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useRecentlyViewed } from "@app/hooks/useRecentlyViewed"
import { useMedia } from "@app/hooks/useMedia"
import RecentlyViewedSwiper from "@app/components/Sections/RecentlyViewed/RecentlyViewedSwiper"
import RecentlyViewedGrid from "@app/components/Sections/RecentlyViewed/RecentlyViewedGrid"
import { AnalyticProps, withSection } from "@app/hoc/Section"

type Props = GatsbyTypes.SanitySectionRecentlyViewed & AnalyticProps

const RecentlyViewed: React.FC<Props> = ({ menuTitle, title, innerRef, handleTrackingClick, tag }) => {
  const { items, loading } = useRecentlyViewed({ limit: 8, firstImages: 1, firstVariants: 1 })
  const { isMedium, isLarge, isXLarge } = useMedia()
  const { saveProductItems } = useAppContext()

  const sectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => saveProductItems(menuTitle, sectionRef), [sectionRef, saveProductItems, menuTitle])

  let componentType = "default"
  if (!isLarge && !isXLarge) componentType = items.length > 2 ? "swiper" : "default"
  if (isMedium && !isLarge) componentType = items.length > 3 ? "swiper" : "default"
  if (isLarge) componentType = items.length > 4 ? "swiper" : "default"

  return loading || items.length ? (
    <Box as="section" ref={sectionRef} py={{ base: "8", lg: "18" }}>
      <Container ref={innerRef} maxW="container.2xl" px={{ base: "0" }}>
        <Box>
          {title ? (
            <Heading as={tag} size="h3" mb={{ base: "3", lg: "8" }} color="typography.headlines900" textAlign="center">
              {title}
            </Heading>
          ) : null}

          {componentType === "swiper" ? (
            <RecentlyViewedSwiper products={items} loading={loading} handleTrackingClick={handleTrackingClick} />
          ) : (
            <RecentlyViewedGrid products={items} loading={loading} handleTrackingClick={handleTrackingClick} />
          )}
        </Box>
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(RecentlyViewed))
