import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Image, Text, LinkBox, LinkOverlay, AspectRatio, Button } from "@chakra-ui/react"

import { useGlobal } from "@app/hooks/useGlobal"
import { useRoutes } from "@app/hooks/useRoutes"
import { useImage } from "@app/hooks/useImage"
import { useShopify, useShopifyProduct } from "@app/hooks/useShopify"
import { useConfigContext } from "@app/providers/config"
import ProductPriceRange from "@app/components/Product/ProductPriceRange"
import ProductBadge from "@app/components/Product/ProductBadge"
import Link from "@app/components/Link"

import type { HandleTrackingClickType } from "@app/hoc/Section"
import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useInView } from "react-intersection-observer"

type Props = {
  item: NormalisedProduct
  itemPosition: number
  itemListName?: string
  handleTrackingClick?: HandleTrackingClickType
}

const FeaturedProductsItem: React.FC<Props> = ({ item, itemPosition, itemListName, handleTrackingClick }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { getGatsbyImage } = useImage()
  const { urlResolver } = useRoutes()
  const { getProductTagValue } = useShopifyProduct()
  const { getFirstAvailableVariant } = useShopify()
  const { global } = useGlobal()
  const { ref, inView } = useInView({ threshold: 0.3 })
  const { trackProductImpression, trackProductClick } = useAnalytics()

  const [isTracked, setIsTracked] = useState(false)

  useEffect(() => {
    if (!isTracked && inView) {
      trackProductImpression(item, itemPosition, itemListName)
      setIsTracked(true)
    }
  }, [isTracked, inView, trackProductImpression, item, itemPosition, itemListName])

  const images = useMemo(() => getGatsbyImage(item.images), [item.images, getGatsbyImage])
  const url = useMemo(() => urlResolver(item, routes.PRODUCT).url, [urlResolver, item, routes.PRODUCT])
  const label = useMemo(() => getProductTagValue("flag:", item.tags) || item.vendor, [item.tags, item.vendor, getProductTagValue])
  const badge = useMemo(() => getProductTagValue("badge:", item.tags), [item.tags, getProductTagValue])

  const trackingClickHandler = useCallback(() => {
    const firstVariant = getFirstAvailableVariant(item) || item.variants[0]
    trackProductClick(item, firstVariant, itemPosition, itemListName)

    if (handleTrackingClick) handleTrackingClick()
  }, [getFirstAvailableVariant, item, trackProductClick, itemPosition, itemListName, handleTrackingClick])

  return (
    <Box ref={ref} pos="relative" d="flex" flexDir="column" justifyContent="space-between" w="full" h="full">
      <LinkBox mb={{ base: "3" }}>
        <Box pos="relative" mb={{ base: "3" }} role="group">
          <AspectRatio ratio={1 / 1} borderRadius="xl" overflow="hidden">
            <Image {...images[0]} />
          </AspectRatio>

          {images.length > 1 && images[1] ? (
            <Box
              pos="absolute"
              top="0"
              left="0"
              w="full"
              h="full"
              zIndex="docked"
              opacity="0"
              transition="0.2s opacity ease"
              _groupHover={{ lg: { opacity: 1 } }}
            >
              <AspectRatio ratio={1 / 1} borderRadius="xl" overflow="hidden">
                <LinkOverlay href={url} onClick={trackingClickHandler} textAlign="left" _hover={{ textDecor: "none" }}>
                  <Image {...images[1]} />
                </LinkOverlay>
              </AspectRatio>
            </Box>
          ) : null}
        </Box>

        <LinkOverlay href={url} onClick={trackingClickHandler} textAlign="left" _hover={{ textDecor: "none" }}>
          {badge && (
            <ProductBadge pos="absolute" top="-4" right="-2" zIndex="docked">
              {badge}
            </ProductBadge>
          )}

          <Text size="headlines" color="typography.headlines900">
            {item.title}
          </Text>

          {label && <Text size="mediumParagraph">{label}</Text>}
          <ProductPriceRange product={item} />
        </LinkOverlay>
      </LinkBox>

      <Button as={Link} to={url} onClick={trackingClickHandler} variant="outline" d="flex">
        {global?.productViewProduct}
      </Button>
    </Box>
  )
}

export default React.memo(FeaturedProductsItem)
