import React from "react"
import { Box, BoxProps } from "@chakra-ui/react"

const ProductBadge: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    as="span"
    d="inline-flex"
    justifyContent="center"
    alignItems="center"
    bg="url(/icons/productBadge.svg) no-repeat center center/100%"
    w={{ base: "16", lg: "18.5" }}
    h={{ base: "16", lg: "18.5" }}
    fontSize={{ base: "2xs", lg: "xs" }}
    lineHeight={{ base: "short" }}
    letterSpacing="widest"
    fontWeight="bold"
    textAlign="center"
    textTransform="uppercase"
    color="brand.secondaryFocus"
    {...props}
  >
    {children}
  </Box>
)

export default React.memo(ProductBadge)
