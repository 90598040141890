import { graphql, useStaticQuery } from "gatsby"

export const useGlobal = () => {
  const { global } = useStaticQuery<GatsbyTypes.StaticGlobalQuery>(graphql`
    query StaticGlobal {
      global: sanityTemplateGlobal {
        productViewProduct
        productAdd
        productAbout
        productSale
      }
    }
  `)

  return {
    global,
  }
}
