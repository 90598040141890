import React from "react"
import { Box, Flex, IconButton, Skeleton } from "@chakra-ui/react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { useMedia } from "@app/hooks/useMedia"
import FeaturedProductsItem from "@app/components/Sections/FeaturedProducts/FeaturedProductsItem"
import { HandleTrackingClickType } from "@app/hoc/Section"
import { Icon } from "@app/components/Icon"

import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"

SwiperCore.use([Navigation])

type Props = {
  products: NormalisedProduct[]
  loading: boolean
  handleTrackingClick: HandleTrackingClickType
}

const RecentlyViewedSwiper: React.FC<Props> = ({ products, loading, handleTrackingClick }) => {
  const { isLarge, isMedium } = useMedia()

  let skeletonCount = 2
  if (isMedium) skeletonCount = 3
  if (isLarge) skeletonCount = 4

  const id = Math.random().toString(16).slice(2)
  const prevId = `o-${id}-swiper-arrow--prev`
  const nextId = `o-${id}-swiper-arrow--next`

  const navButtonStyles = {
    d: "inline-flex",
    w: "10",
    h: "10",
    borderRadius: "full",
    bg: "background.white",
    boxShadow: "0px 2px 3px rgba(58, 21, 10, 0.1)",
    _focus: { boxShadow: "0px 2px 3px rgba(58, 21, 10, 0.1)" },
  }

  return (
    <Box
      pos="relative"
      ml={{ base: "4", lg: "20" }}
      mr={{ lg: "20" }}
      sx={{ ".swiper": { paddingTop: "4", alignItems: "stretch" }, ".swiper-slide": { height: "auto" } }}
    >
      <Swiper
        lazy
        loop
        slidesPerView={2.3}
        spaceBetween={16}
        breakpoints={{
          750: {
            slidesPerView: 3.4,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 32,
            navigation: { prevEl: `#${prevId}`, nextEl: `#${nextId}` },
          },
        }}
      >
        {products.length && !loading
          ? products.map((product, index) => (
              <SwiperSlide key={product.id}>
                <FeaturedProductsItem
                  item={product}
                  itemPosition={index}
                  itemListName="Recently Viewed Products Carousel"
                  handleTrackingClick={handleTrackingClick}
                />
              </SwiperSlide>
            ))
          : [...Array(skeletonCount)].map((i, index) => (
              <SwiperSlide key={index}>
                <Skeleton w="full" pb="178%"></Skeleton>
              </SwiperSlide>
            ))}
      </Swiper>

      <Flex
        d={{ base: "none", lg: products.length > 4 ? "flex" : "none" }}
        pos="absolute"
        top="calc(50% - 50px)"
        left="50%"
        transform="translate(-50%, -50%)"
        w={{ base: "calc(100% + 40px)" }}
        zIndex="overlay"
        justifyContent="space-between"
        pointerEvents="none"
      >
        <IconButton
          variant="unstyled"
          aria-label="Previous"
          icon={<Icon name="arrows/chevron-left" width="24" height="24" />}
          id={prevId}
          {...navButtonStyles}
          pointerEvents="initial"
        />
        <IconButton
          variant="unstyled"
          aria-label="Next"
          icon={<Icon name="arrows/chevron-right" width="24" height="24" />}
          id={nextId}
          {...navButtonStyles}
          pointerEvents="initial"
        />
      </Flex>
    </Box>
  )
}

export default React.memo(RecentlyViewedSwiper)
