import { useCallback, useEffect, useState } from "react"

import { useAppContext } from "@app/providers/app"
import { useProductsContext } from "@app/providers/products"
import { useShopify } from "@app/hooks/useShopify"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"

export const useRecentlyViewed = (options?: RecentlyViewedPayload): UseRecentlyViewed => {
  const [items, setItems] = useState<NormalisedProduct[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { activeProduct } = useAppContext()
  const { recent: allRecent } = useProductsContext()
  const { getProducts } = useShopify()

  const fetchItems = useCallback(async () => {
    setItems([])
    const recent = activeProduct ? allRecent.filter(handle => activeProduct?.handle !== handle) : []

    if (recent.length > 0) {
      setLoading(true)
      const items = await getProducts({ handles: recent.slice(0, options?.limit ? options.limit : recent.length), ...(options || {}) })

      setItems(
        items.filter(Boolean).filter(item => {
          const lcTags = (item?.tags as string[]).map(s => s.toLowerCase())
          return activeProduct?.handle !== item.handle && lcTags.includes("readymadepack")
        })
      )
      setLoading(false)
    }
  }, [activeProduct, allRecent, getProducts, options, setItems, setLoading])

  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRecent])

  return {
    items,
    loading,
  }
}

export type RecentlyViewedPayload = {
  countryCode?: string
  firstCollections?: number
  firstImages?: number
  firstMedia?: number
  firstMetafields?: number
  firstVariants?: number
  limit?: number
}

type UseRecentlyViewed = {
  items: NormalisedProduct[]
  loading: boolean
}
