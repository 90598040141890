import React from "react"
import { Grid, GridItem, Skeleton } from "@chakra-ui/react"

import { useMedia } from "@app/hooks/useMedia"
import { HandleTrackingClickType } from "@app/hoc/Section"
import FeaturedProductsItem from "@app/components/Sections/FeaturedProducts/FeaturedProductsItem"

import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"

type Props = {
  products: NormalisedProduct[]
  loading: boolean
  handleTrackingClick: HandleTrackingClickType
}

const RecentlyViewedGrid: React.FC<Props> = ({ products, loading, handleTrackingClick }) => {
  const { isMedium, isLarge } = useMedia()

  let skeletonCount = 2
  if (isMedium) skeletonCount = 3
  if (isLarge) skeletonCount = 4

  return (
    <Grid
      templateColumns={{ base: "repeat(2, calc(50% - 8px))", md: "repeat(3, calc(33.33% - 10.66px))", lg: "repeat(4, 1fr)" }}
      gap={{ base: "4", lg: "8" }}
      px={{ base: "4", lg: "20" }}
    >
      {products.length && !loading
        ? products.map((product, index) => (
            <GridItem key={product.id}>
              <FeaturedProductsItem
                item={product}
                itemPosition={index}
                itemListName="Recently Viewed Products Grid"
                handleTrackingClick={handleTrackingClick}
              />
            </GridItem>
          ))
        : [...Array(skeletonCount)].map((i, index) => (
            <GridItem key={index}>
              <Skeleton w="full" pb="172%"></Skeleton>
            </GridItem>
          ))}
    </Grid>
  )
}

export default React.memo(RecentlyViewedGrid)
